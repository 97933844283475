import React from 'react'
import {Button, Modal} from 'react-bootstrap'
import {Link} from 'gatsby'
import 'components/css/modal.css'
import {Span} from 'components'

function Sales() {
  return (
    <div className="modal-view">
      <Link to="/careers" state={{ modal: false }}>
        <Button className="close" id="close-button">
         <Span aria-hidden="true">x</Span>
        </Button>
      </Link>

      <h2>BUSINESS DEVELOPMENT MANAGER</h2><hr/>
    <br/>
      <Modal.Title><h4>MINIMUM QUALIFICATIONS</h4></Modal.Title>
      <Modal.Body>
        <ul>
          <li>Market knowledge</li>
          <li>At least 3 years of working experience in Sales with People Management</li>
          <li>Exceptional communication and presentation skills</li>
          <li>Proficiency in English</li>
          <li>Proficiency in MS Office and CRM software (e.g. Salesforce)</li>
          <li>Self-starter, goal-oriented and flexible</li>
          <li>Ability to identify business value drivers- opportunities, competition, key trends etc.</li>
          <li>Commercial awareness and strong business and marketing analysis skills and in-depth knowledge of the economic environment, customer needs, competitor behavior and internal operations.</li>
        </ul>
      </Modal.Body>
      <a href="mailto: careers@digipay.ph" className="resume"><Button className="resume-button">Send Resume</Button></a>
    </div>
  )
}

export default Sales
